<template>
	<div class="layout-topbar">
		<router-link to="/dashboard" class="layout-topbar-logo">
			<Image src="/app/front/images/logo_tim.png" alt="Image" />

		</router-link>

		<Image src="/app/front/images/xerox.png" alt="Image" width="50" />
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button" v-styleclass="{
			selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true
		}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li class="mr-3">
				<Button v-if="mostrarfinanceiro" type="button" size="small" label="Manual Financeiro"
					icon="pi pi-question-circle" @click="manualFinanceiro()" />
			</li>
			<li>
				<Button v-if="mostrarfornecedor" type="button" size="small" label="Manual Fornecedor"
					icon="pi pi-question-circle" @click="manualFornecedor()" />
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mostrarfinanceiro:false,
			mostrarfornecedor:false,
		}
	},
	methods: {
		manualFinanceiro() {
			window.open("/app/front/manual_financeiro.pdf");

		},
		manualFornecedor() {
			window.open("/app/front/manual_fornecedor.pdf");
		},
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},
		onTopbarMenuToggle(event) {
			this.$emit('topbar-menu-toggle', event);
		},
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		}
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	mounted() {
		let user = localStorage.getItem('user');
		user = JSON.parse(user);

		if (user.id_empresa == 22845207) {
			this.mostrarfinanceiro = true;
			this.mostrarfornecedor = true;
		}

		if (user.id_tipo == 22890835) {
			this.mostrarfinanceiro = false;
			this.mostrarfornecedor = true;
		}
		if (user.id_tipo == 22913699) {
			this.mostrarfinanceiro = false;
			this.mostrarfornecedor = true;
		}
		if (user.id_tipo == 22959227) {
			this.mostrarfinanceiro = true;
			this.mostrarfornecedor = false;
		}
	}
}
</script>
<style scoped>
.layout-topbar .layout-topbar-logo {
	width: auto;
}
</style>